<template>
  <a-page-header @back="() => $router.go(-1)" title="TABLA NUTRICIONAL NIÑOS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Nutricional</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Tabla nutricional niños</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <a-tabs v-model:activeKey="activeKey" class="-mt-8">
    <a-tab-pane key="1" tab="TALLA PARA EDAD">
      <div class="flex mb-4">
        <fieldset class="border border-solid border-gray-300 p-3 px-8 w-full">
          <!-- <legend class="text-sm text-center ml-2">Filtros por edad</legend> -->
          <NiniosFiltrosPeso @fetchEdad="fetchEdad" />
        </fieldset>
      </div>
      <div class="overflow-x-auto w-full bg-white border-2 rounded-lg p-4">
        <a-table
          size="small"
          rowKey="idpesoedadninio"
          :bordered="true"
          :columns="columnsEdad"
          :data-source="data_edad"
          :pagination="false"
          :loading="isLoading1"
          :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white ' : 'bg-gray-100 ')"
        >
          <template #rangepages="{ index }">
            <span> {{ index + 1 }} </span>
          </template>

          <template #pesonormalmenorigual2de="{ text: pesonormalmenorigual2de }">
            <div class="bg-blue-200 px-3 py-3.5 -my-3 text-center">
              {{ pesonormalmenorigual2de }}
            </div>
          </template>

          <template #pesonormalmayoigualmenos2de="{ text: pesonormalmayoigualmenos2de }">
            <div class="bg-blue-200 px-3 py-3.5 -my-3 text-center">
              {{ pesonormalmayoigualmenos2de }}
            </div>
          </template>

          <template #edadaniosmeses="{ text: edadaniosmeses }">
            <div class="px-3 py-3.5 -my-3 text-center border-r-2 border-l-2 border-black">
              {{ edadaniosmeses }}
            </div>
          </template>

          <template #tallabajamayorigualmenos3de="{ text: tallabajamayorigualmenos3de }">
            <div class="px-3 py-3.5 -my-3 text-center border-r-2 border-l-2 border-blue-500">
              {{ tallabajamayorigualmenos3de }}
            </div>
          </template>

          <template #tallanormalmayorigualmenos2de="{ text: tallanormalmayorigualmenos2de }">
            <div class="bg-blue-200 px-3 py-3.5 -my-3 text-center">
              {{ tallanormalmayorigualmenos2de }}
            </div>
          </template>

          <template #tallanormalmenos1de="{ text: tallanormalmenos1de }">
            <div class="bg-blue-300 px-3 py-3.5 -my-3 text-center">
              {{ tallanormalmenos1de }}
            </div>
          </template>

          <template #tallanormal1de="{ text: tallanormal1de }">
            <div class="bg-blue-400 px-3 py-3.5 -my-3 text-center">
              {{ tallanormal1de }}
            </div>
          </template>

          <template #tallanormalmenorigual2de="{ text: tallanormalmenorigual2de }">
            <div class="bg-blue-500 px-3 py-3.5 -my-3 text-center">
              {{ tallanormalmenorigual2de }}
            </div>
          </template>

          <template #tipomedicion="{ text: tipomedicion }">
            <span>
              <a-tag :color="tipomedicion == '1' ? 'green' : 'volcano'">
                {{ tipomedicion == 1 ? "Longitud (Medido echado)" : "Estatura (Medido de pie)" }}
              </a-tag>
            </span>
          </template>
        </a-table>
      </div>
    </a-tab-pane>

    <a-tab-pane key="2" tab="PESO PARA TALLA">
      <div class="flex mb-4">
        <fieldset class="border border-solid border-gray-300 p-3 px-8 w-full">
          <NiniosFiltrosTalla @fetchTalla="fetchTalla" />
        </fieldset>
      </div>
      <div class="overflow-x-auto w-full bg-white border-2 rounded-lg p-4">
        <a-table
          size="small"
          rowKey="idpesotallaninio"
          :bordered="true"
          :columns="columnsTalla"
          :data-source="data_talla"
          :pagination="false"
          :loading="isLoading2"
          :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
        >
          <template #rangepages="{ index }">
            <span> {{ index + 1 }} </span>
          </template>

          <template
            #pesodesnutricionmayorigualmenos3de="{ text: pesodesnutricionmayorigualmenos3de }"
          >
            <div class="px-3 py-3.5 -my-3 text-center border-r-2 border-l-2 border-blue-500">
              {{ pesodesnutricionmayorigualmenos3de }}
            </div>
          </template>

          <template #pesonormalmayorigualmenos2de="{ text: pesonormalmayorigualmenos2de }">
            <div class="bg-blue-200 px-3 py-3.5 -my-3 text-center">
              {{ pesonormalmayorigualmenos2de }}
            </div>
          </template>

          <template #pesonormalmenos1de="{ text: pesonormalmenos1de }">
            <div class="bg-blue-400 px-3 py-3.5 -my-3 text-center">
              {{ pesonormalmenos1de }}
            </div>
          </template>

          <template #pesonormal1de="{ text: pesonormal1de }">
            <div class="bg-blue-400 px-3 py-3.5 -my-3 text-center">
              {{ pesonormal1de }}
            </div>
          </template>

          <template #pesonormalmenorigual2de="{ text: pesonormalmenorigual2de }">
            <div class="bg-blue-200 px-3 py-3.5 -my-3 text-center">
              {{ pesonormalmenorigual2de }}
            </div>
          </template>

          <template #pesosobrepesomenorigual3de="{ text: pesosobrepesomenorigual3de }">
            <div class="px-3 py-3.5 -my-3 text-center border-r-2 border-l-2 border-blue-500">
              {{ pesosobrepesomenorigual3de }}
            </div>
          </template>

          <template #tipomedicion="{ text: tipomedicion }">
            <span>
              <a-tag :color="tipomedicion == '1' ? 'green' : 'volcano'">
                {{ tipomedicion == 1 ? "Longitud (Medido echado)" : "Estatura (Medido de pie)" }}
              </a-tag>
            </span>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
    <template #renderTabBar="{ DefaultTabBar, ...props }">
      <component :is="DefaultTabBar" v-bind="props" :style="{ textAlign: 'center' }" />
    </template>
  </a-tabs>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { columnsEdad, columnsTalla } from "./utilsNinios";
import { useStore } from "vuex";
import UsuariosApi from "@/api/user";
import {
  FileAddOutlined,
  FormOutlined,
  DeleteOutlined,
  SearchOutlined,
  ReloadOutlined,
  IssuesCloseOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons-vue";
import NiniosApi from "@/api/ninio";
import NiniosFiltrosPeso from "./NiniosFiltrosPeso.vue";
import NiniosFiltrosTalla from "./NiniosFiltrosTalla.vue";

export default {
  name: "NutricionalNinios",
  components: {
    NiniosFiltrosPeso,
    NiniosFiltrosTalla,
    FileAddOutlined,
    FormOutlined,
    DeleteOutlined,
    SearchOutlined,
    ReloadOutlined,
    IssuesCloseOutlined,
    ExclamationCircleOutlined
  },
  setup() {
    const store = useStore();
    const data_edad = ref([]);
    const data_talla = ref([]);
    const isLoading1 = ref(false);
    const isLoading2 = ref(false);
    const { establecimiento } = store.state.user.usuario;

    const fetchEdad = () => {
      const search = store.getters["ninios/search1"];

      isLoading1.value = true;
      NiniosApi.getAllEdad(search)
        .then((response) => {
          data_edad.value = response.data;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading1.value = false));
    };

    const fetchTalla = () => {
      const search = store.getters["ninios/search2"];
      isLoading2.value = true;

      NiniosApi.getAllTalla(search)
        .then((response) => {
          data_talla.value = response.data;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading2.value = false));
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Nutricional", "Tabla Nutricional Niños", establecimiento);
      fetchEdad();
      fetchTalla();
    });

    onUnmounted(() => {
      store.dispatch("ninios/cleanFilters");
    });

    return {
      activeKey: ref("1"),
      columnsEdad,
      columnsTalla,
      data_edad,
      data_talla,
      isLoading1,
      isLoading2,
      fetchEdad,
      fetchTalla
    };
  }
};
</script>

<style lang="less" scoped>
.tallanormalmenos1de {
  background-color: pink;
}
</style>
