export const columnsEdad = [
  {
    title: "PESO PARA EDAD",
    children: [
      {
        title: "Desnutrición",
        children: [
          {
            title: "<-2DE",
            dataIndex: "pesodesnutricionmenormenos2de",
            key: "pesodesnutricionmenormenos2de"
          }
        ]
      },
      {
        title: "NORMAL",
        children: [
          {
            title: "≥-2DE",
            dataIndex: "pesonormalmayoigualmenos2de",
            key: "pesonormalmayoigualmenos2de",
            slots: { customRender: "pesonormalmayoigualmenos2de" }
          },
          {
            title: "≤2DE",
            dataIndex: "pesonormalmenorigual2de",
            key: "pesonormalmenorigual2de",
            slots: { customRender: "pesonormalmenorigual2de" }
          }
        ]
      },
      {
        title: "Sobrepeso",
        children: [
          {
            title: ">2DE",
            dataIndex: "pesosobrepesomayor2de",
            key: "pesosobrepesomayor2de"
          }
        ]
      }
    ]
  },
  {
    title: "TALLA PARA EDAD",
    children: [
      {
        title: "EDAD (Año y meses)",
        dataIndex: "edadaniosmeses",
        key: "edadaniosmeses",
        slots: { customRender: "edadaniosmeses" },
        width: 110
      },
      {
        title: "TALLA (cm) (longitud/estatura)",
        children: [
          {
            title: "Baja severa",
            children: [
              {
                title: "<-3DE",
                dataIndex: "tallabajaseveramenormenos3de",
                key: "tallabajaseveramenormenos3de"
              }
            ]
          },
          {
            title: "Baja",
            children: [
              {
                title: "≥-3DE",
                dataIndex: "tallabajamayorigualmenos3de",
                key: "tallabajamayorigualmenos3de",
                slots: { customRender: "tallabajamayorigualmenos3de" }
              }
            ]
          },
          {
            title: "NORMAL",
            children: [
              {
                title: "≥-2DE",
                dataIndex: "tallanormalmayorigualmenos2de",
                key: "tallanormalmayorigualmenos2de",
                slots: { customRender: "tallanormalmayorigualmenos2de" }
              },
              {
                title: "-1DE",
                dataIndex: "tallanormalmenos1de",
                key: "tallanormalmenos1de",
                slots: { customRender: "tallanormalmenos1de" }
              },
              {
                title: "1DE",
                dataIndex: "tallanormal1de",
                key: "tallanormal1de",
                slots: { customRender: "tallanormal1de" }
              },
              {
                title: "≤2DE",
                dataIndex: "tallanormalmenorigual2de",
                key: "tallanormalmenorigual2de",
                slots: { customRender: "tallanormalmenorigual2de" }
              }
            ]
          },
          {
            title: "Alta",
            children: [
              {
                title: ">2DE",
                dataIndex: "tallaaltamayor2de",
                key: "tallaaltamayor2de",
                slots: { customRender: "tallaaltamayor2de" }
              }
            ]
          }
        ]
      },
      {
        title: "TIPO DE MEDICIÓN",
        dataIndex: "tipomedicion",
        key: "tipomedicion",
        slots: { customRender: "tipomedicion" }
      }
    ]
  }
];

export const columnsTalla = [
  {
    title: "TALLA (cm)",
    dataIndex: "tallacentimetros",
    key: "tallacentimetros"
  },
  {
    title: "PESO PARA TALLA",
    children: [
      {
        title: "PESO (Kg)",
        children: [
          {
            title: "Desnutrición severa",
            children: [
              {
                title: "<-3DE",
                dataIndex: "pesodesnutricionseveramenormenos3de",
                key: "pesodesnutricionseveramenormenos3de"
              }
            ]
          },
          {
            title: "Desnutrición",
            children: [
              {
                title: "≥-3DE",
                dataIndex: "pesodesnutricionmayorigualmenos3de",
                key: "pesodesnutricionmayorigualmenos3de",
                slots: { customRender: "pesodesnutricionmayorigualmenos3de" }
              }
            ]
          },
          {
            title: "NORMAL",
            children: [
              {
                title: "≥-2DE",
                dataIndex: "pesonormalmayorigualmenos2de",
                key: "pesonormalmayorigualmenos2de",
                slots: { customRender: "pesonormalmayorigualmenos2de" }
              },
              {
                title: "-1DE",
                dataIndex: "pesonormalmenos1de",
                key: "pesonormalmenos1de",
                slots: { customRender: "pesonormalmenos1de" }
              },
              {
                title: "1DE",
                dataIndex: "pesonormal1de",
                key: "pesonormal1de",
                slots: { customRender: "pesonormal1de" }
              },
              {
                title: "≤2DE",
                dataIndex: "pesonormalmenorigual2de",
                key: "pesonormalmenorigual2de",
                slots: { customRender: "pesonormalmenorigual2de" }
              }
            ]
          },
          {
            title: "Sobrepeso",
            children: [
              {
                title: "≤3DE",
                dataIndex: "pesosobrepesomenorigual3de",
                key: "pesosobrepesomenorigual3de",
                slots: { customRender: "pesosobrepesomenorigual3de" }
              }
            ]
          },
          {
            title: "Obesidad",
            children: [
              {
                title: ">3DE",
                dataIndex: "pesoobesidadmayor3de",
                key: "pesoobesidadmayor3de",
                slots: { customRender: "pesoobesidadmayor3de" }
              }
            ]
          }
        ]
      },
      {
        title: "TIPO DE MEDICIÓN",
        dataIndex: "tipomedicion",
        key: "tipomedicion",
        slots: { customRender: "tipomedicion" }
      }
    ]
  }
];
