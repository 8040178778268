import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAllEdad: (search) => {
    var buscar = search.length > 1 ? `${search}` : `%%`;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_tvna_pesoedadninio",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "edadaniosmeses",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: buscar,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllTalla: (search) => {
    var buscar = search.length > 0 ? `${search}` : `%%`;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_tvna_pesotallaninio",
          type: "string"
        },
        {
          name: "@filtros_AND",
          value: "tallacentimetros",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: buscar,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }
};
